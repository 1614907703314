<template>
  <div class="wrapper rhombus-bg">
    <div class="cover">
      <div class="rel-center text-center">
        <h2 class="mobile" style="color: #fff">
          THANK YOU FOR YOUR INTEREST IN
        </h2>
        <h2 style="color: #fff">Club de Ejecutivos</h2>
        <p class="p" style="text-align: center; color: #fff">
          Please fill in the following form to continue
        </p>
      </div>
    </div>
    <div class="container">
      <!-- THANK YOU for payment -->
      <div class="form-wrap thank-you" v-if="respStatus === 'success'">
        Thank you, <strong>{{ respName }}</strong
        >. Soon we will contact you in <strong>{{ respEmail }}</strong
        ><br />
        If you have entered a wrong email, please contact us in
        <strong>info@aicad.es</strong> with your order number:
        <strong>{{ respOrder }}</strong>
      </div>
      <!--  -->
      <div class="form-wrap text-center" v-if="obj">
        <h2 style="font-weight: 200;">Paquete Servicios Seleccionada</h2>
        <h3 style="color: #009090">
          {{ obj.name }} (
          {{
            obj.isAnnual ? $t("plans.cards.annual") : $t("plans.cards.monthly")
          }}
          ) - <strong>{{ obj.price }}€</strong>
          {{
            obj.isAnnual
              ? $t("plans.cards.per_year")
              : $t("plans.cards.per_month")
          }}
        </h3>

        <form
          class="contact3-form validate-form"
          @submit.prevent="selectPlan"
          v-if="!sentSuccess"
        >
          <div
            class="wrap-input3 validate-input"
            data-validate="Name is required"
          >
            <input
              class="input3"
              type="text"
              name="name"
              v-model="form.name"
              :placeholder="'*' + $t('contact.placeholders.name')"
              required
            />
            <span class="focus-input3"></span>
          </div>

          <div
            class="wrap-input3 validate-input"
            data-validate="Valid email is required: ex@abc.xyz"
          >
            <input
              class="input3"
              type="email"
              name="email"
              v-model="form.email"
              :placeholder="'*' + $t('contact.placeholders.email')"
              required
            />
            <span class="focus-input3"></span>
          </div>

          <div class="mb-3" style="font-size: 1.2rem;">
            Total to pay: {{ obj.price }}€
          </div>

          <h3 v-if="sentFailed" style="color: #ff9ca6;">
            {{ $t("contact.something_wrong") }}
          </h3>

          <button href="#" type="submit" class="fancy-button bg-gradient2">
            <span>Proceed to payment</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      obj: null,
      sentSuccess: false,
      sentFailed: false,

      form: {
        name: null,
        email: null
      },

      respStatus: null,
      respName: null,
      respEmail: null,
      respOrder: null
    };
  },
  mounted() {
    this.obj = this.$route.params.obj;

    // GET Params from URL
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    this.respStatus = params.get("payment");
    this.respName = params.get("name");
    this.respEmail = params.get("email");
    this.respOrder = params.get("orderid");

    if (this.respStatus === "success") {
      console.log("YOYO");
      this.axios.get(
        "https://clubejecutivo.aicad.es/spain/payment/index.php?mode=success&price=" +
          this.obj.price +
          "&plan=" +
          this.obj.name +
          "&isannual=" +
          this.obj.isAnnual +
          "&name=" +
          this.form.name +
          "&email=" +
          this.form.email
      );
    }
  },
  methods: {
    selectPlan() {
      //   window.open(
      //     "/payment/index.php?price=" +
      //       this.obj.price +
      //       "&plan=" +
      //       this.obj.name +
      //       "&isannual=" +
      //       this.obj.isAnnual
      //   );
      window.open(
        "https://clubejecutivo.aicad.es/spain/payment/index.php?mode=pay&price=" +
          this.obj.price +
          "&plan=" +
          this.obj.name +
          "&isannual=" +
          this.obj.isAnnual +
          "&name=" +
          this.form.name +
          "&email=" +
          this.form.email
      );
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/css/master-postgrado/bootstrap.min.css";
@import "../../assets/css/master-postgrado/main.min.css";
.cover {
  background: url(https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/hero-bg.jpg);
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: calc(400px);
  z-index: -1;
  overflow: hidden;
}

.rel-center {
  position: relative;
  top: calc(50% - 25px);
  left: 50%;
  transform: translate(-50%, -50%);
}

h2 {
  font-family: "DMSans", sans-serif;
  font-size: 3rem !important;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;
}

.p {
  font-family: "DMSans-Italic", sans-serif;
  color: #848484;
  font-size: 1.5rem;
}

.form-wrap {
  background: #f3f3f3;
  border-radius: 25px;
  top: -100px;
  position: relative;
  margin-bottom: -100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 25px 16px;
}

.divide {
  height: 100vh;
  r .left {
    background: red;
  }
}

.fancy-button {
  font-size: 1.4rem;
  display: inline-block;
  margin: 0px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
}

/* Mixins */
@mixin gradientBg($startColor, $endColor) {
  background: $startColor;
  background: -moz-linear-gradient(
    left,
    $startColor 0%,
    $endColor 80%,
    $endColor 100%
  );
  background: -webkit-linear-gradient(
    left,
    $startColor 0%,
    $endColor 80%,
    $endColor 100%
  );
  background: linear-gradient(
    to right,
    $startColor 0%,
    $endColor 80%,
    $endColor 100%
  );
}

/* bg shortcodes */
.bg-gradient1 span,
.bg-gradient2 span,
.bg-gradient2:before {
  @include gradientBg(#3fc767, #33d8ba);
}

.fancy-button span {
  display: inline-block;
  padding: 15px 45px;
  border-radius: 50em;
  position: relative;
  z-index: 2;
  will-change: transform, filter;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

// FORM
.input3 {
  color: #333;

  &::placeholder {
    color: #333;
  }
}

.wrap-input3 {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.focus-input3::before {
  background: #777;
}

.contact3-form {
  width: 55%;
  margin: auto;
  transform: unset;
  top: unset;
  margin-top: 4rem;
  padding: 2rem;

  @media screen and (max-width: 767px) {
    & {
      width: 95%;
    }
  }
}

.rhombus-bg {
  background: url(https://clubejecutivo.s3.us-east-2.amazonaws.com/new_imgs/rhombus-bg.png);
  background-position: 0 108vh;
}

.form-wrap.thank-you {
  font-size: 1.4rem;
}
</style>
